import React, { useState } from "react";
import { motion, AnimatePresence, easeInOut } from "framer-motion";
import styles from "./style.module.scss";
import { SectionTitle, Anchor } from "../../styledComponent";
import sectionImg from "../../../assets/impLinks.jpg";
import { staffPagesData } from "../../../utils/constants";
import CustomAccordian from "../../customAccordian";

const ImportantLinks = (props) => {
  const [active, setActive] = useState(false);
  const show = {
    flexBasis: "600px",
    transformOrigin: "center",
    backgroundColor: "transparent",
    backgroundImage: `url(${sectionImg})`,
  };

  const hide = {
    flexBasis: "200px",
    transformOrigin: "center",
    backgroundColor: "var(--primaryColor)",
    backgroundImage: "none",
    transitionEnd: {
      flexBasis: "200px",
      backgroundColor: "var(--primaryColor)",
      backgroundImage: "none",
    },
  };
  return (
    <motion.div
      animate={props.isActive ? show : hide}
      transition={{ duration: 0.5 }}
      className={`${props.isActive ? styles.activeBox : styles.inActiveBox} ${
        styles.sectionContainer
      }`}
      onClick={() => props.onClick()}
    >
      <AnimatePresence initial={false}>
        {!props.isActive && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, scale: 1 },
              collapsed: { opacity: 0, scale: 0 },
            }}
            transition={{
              delay: 0.4,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
            className={styles.sectionTitle}
          >
            <SectionTitle>Important Links</SectionTitle>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence initial={true}>
        {props.isActive && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, scale: 1 },
              collapsed: { opacity: 0, scale: 0 },
            }}
            transition={{
              easings: easeInOut,
            }}
            className={styles.sectionContent}
          >
            <div className={styles.sectionTitle}>
              <SectionTitle>Important Links</SectionTitle>
            </div>
            <div className={styles.sectionInner}>
              <div className={styles.btnContainer}>
                {staffPagesData.map((val, i) => {
                  if (val.hasChildrens) {
                    return (
                      <CustomAccordian
                        title={val.name}
                        childrenData={val.childrenData}
                        id={val.id}
                        key={i}
                        currentAccordian={active === val.id ? true : false}
                        activeAccordian={(val) => setActive(val)}
                      />
                    );
                  } else {
                    return (
                      <Anchor href={val.link} target="_blank" key={i}>
                        {val.name}
                      </Anchor>
                    );
                  }
                })}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ImportantLinks;

import React from "react";
import { AnimatePresence, easeInOut, motion } from "framer-motion";
import styles from "./style.module.scss";
import { Anchor } from "../../styledComponent";
import logo from "../../../assets/logo.png";
import mainImg from "../../../assets/mainImg.jpg";
import { aimgaLoginAndSupportLink } from "../../../utils/constants";

const Main = (props) => {
  const show = {
    flexBasis: "600px",
    transformOrigin: "center",
  };

  const hide = {
    flexBasis: "200px",
    transformOrigin: "center",
    transitionEnd: {
      flexBasis: "200px",
    },
  };
  return (
    <motion.div
      animate={props.isActive ? show : hide}
      transition={{ duration: 0.5 }}
      className={`${props.isActive ? styles.activeBox : styles.inActiveBox} ${
        styles.sectionContainer
      }`}
      onClick={() => props.onClick()}
    >
      <AnimatePresence initial={false}>
        {!props.isActive && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, scale: 1 },
              collapsed: { opacity: 0, scale: 0 },
            }}
            transition={{
              delay: 0.4,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
            className={styles.logoContainer}
          >
            <img src={logo} alt="AIMGA" />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence initial={true}>
        {props.isActive && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, scale: 1 },
              collapsed: { opacity: 0, scale: 0 },
            }}
            transition={{
              easings: easeInOut,
            }}
            className={styles.sectionContent}
          >
            <div className={styles.logoContainer}>
              <img src={logo} alt="AIMGA" />
            </div>
            <div className={styles.sectionInner}>
              <img src={mainImg} alt="AIMGA" />
              <div className={styles.btnContainer}>
                <Anchor
                  href={aimgaLoginAndSupportLink.STAFF_LOGIN}
                  target="_blank"
                >
                  STAFF LOGIN
                </Anchor>
                <Anchor
                  href={aimgaLoginAndSupportLink.TECH_SUPPORT}
                  target="_blank"
                >
                  TECH SUPPORT
                </Anchor>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Main;

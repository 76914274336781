export const BASE_URL = "https://aimga.ca";

export const staffPagesData = [
  {
    id: 1,
    name: "WEBSITE STATS",
    link: `${BASE_URL}/website-stats/`,
    hasChildrens: false,
  },
  {
    id: 2,
    name: "ATTENDANCE",
    link: `${BASE_URL}/attendance/`,
    hasChildrens: false,
  },
  {
    id: 3,
    name: "SERVICE MANAGER",
    link: `${BASE_URL}/service-manager/`,
    hasChildrens: false,
  },
  {
    id: 4,
    name: "MILESTONE TRACKER",
    link: `${BASE_URL}/milestone-tracker/`,
    hasChildrens: false,
  },
  {
    id: 5,
    name: "LETTER GENERATOR",
    link: `${BASE_URL}/letter-generator/`,
    hasChildrens: false,
  },
  {
    id: 6,
    name: "STAFF CALENDAR",
    link: `${BASE_URL}/staff-calendar/`,
    hasChildrens: false,
  },
  {
    id: 7,
    name: "ADMIN PAGES",
    link: `${BASE_URL}/wp-admin`,
    hasChildrens: false,
  },
  {
    id: 8,
    name: "GROUPS",
    hasChildrens: true,
    childrenData: [
      {
        name: "GROUP MANAGER",
        link: `${BASE_URL}/group-manager/`,
      },
      {
        name: "LEARNDASH",
        link: `${BASE_URL}/learndash/`,
      },
    ],
  },
  {
    id: 9,
    name: "REPORTS",
    hasChildrens: true,
    childrenData: [
      {
        name: "MEMBER NOTES",
        link: "https://aimga.ca/report-member-notes/",
      },
      {
        name: "MILESTONES",
        link: "https://aimga.ca/report-milestones/",
      },
      {
        name: "MEMBERSHIP",
        link: "https://aimga.ca/report-membership/",
      },
      {
        name: "NEEDS ASSESSMENTS",
        link: "https://aimga.ca/report-needs-assessments/",
      },
      {
        name: "USER PROFILE",
        link: "https://aimga.ca/report-user-profile/",
      },
      {
        name: "LEARNDASH QUIZZES",
        link: "https://aimga.ca/report-learndash-quizzes/",
      },
      {
        name: "CTP COHORT",
        link: "https://aimga.ca/report-ctp-cohort/",
      },
    ],
  },
  {
    id: 10,
    name: "PROGRAM DELIVERY",
    hasChildrens: true,
    childrenData: [
      {
        name: "STATS",
        link: `${BASE_URL}/program-delivery-stats/`,
      },
      {
        name: "SESSIONS",
        link: `${BASE_URL}/program-delivery-sessions/`,
      },
      {
        name: "CLIENTS",
        link: `${BASE_URL}/program-delivery-clients/`,
      },
      {
        name: "UNIQUE CLIENTS",
        link: `${BASE_URL}/program-delivery-unique-clients/`,
      },
    ],
  },
];

export const howToData = [
  {
    id: 1,
    name: "Question ",
    hasChildrens: true,
    childrenData: null,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent a lorem augue. Nulla molestie facilisis vehicula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce ac sollicitudin sem. Proin quis erat sit amet nunc sollicitudin auctor eu mattis turpis. In consectetur porta sagittis. Morbi iaculis gravida leo nec laoreet. Morbi et diam facilisis, commodo sem at, aliquet eros. Duis auctor dui mauris, tincidunt semper metus porta vel.",
  },
  {
    id: 2,
    name: "Question ",
    hasChildrens: true,
    childrenData: null,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent a lorem augue. Nulla molestie facilisis vehicula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce ac sollicitudin sem. Proin quis erat sit amet nunc sollicitudin auctor eu mattis turpis. In consectetur porta sagittis. Morbi iaculis gravida leo nec laoreet. Morbi et diam facilisis, commodo sem at, aliquet eros. Duis auctor dui mauris, tincidunt semper metus porta vel.",
  },
  {
    id: 3,
    name: "Question ",
    hasChildrens: true,
    childrenData: null,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent a lorem augue. Nulla molestie facilisis vehicula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce ac sollicitudin sem. Proin quis erat sit amet nunc sollicitudin auctor eu mattis turpis. In consectetur porta sagittis. Morbi iaculis gravida leo nec laoreet. Morbi et diam facilisis, commodo sem at, aliquet eros. Duis auctor dui mauris, tincidunt semper metus porta vel.",
  },
  {
    id: 4,
    name: "Question ",
    hasChildrens: true,
    childrenData: null,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent a lorem augue. Nulla molestie facilisis vehicula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce ac sollicitudin sem. Proin quis erat sit amet nunc sollicitudin auctor eu mattis turpis. In consectetur porta sagittis. Morbi iaculis gravida leo nec laoreet. Morbi et diam facilisis, commodo sem at, aliquet eros. Duis auctor dui mauris, tincidunt semper metus porta vel.",
  },
];

export const staffContactData = [
  {
    id: 1,
    name: "Executive Director",
    hasChildrens: true,
    staffData: [
      {
        name: "DEIDRE LAKE",
        email: "deidre@aimga.ca",
        phone: "587-391-2068",
      },
    ],
  },
  {
    id: 2,
    name: "Administrative Team",
    hasChildrens: true,
    staffData: [
      {
        name: "Melat Gebresilassie",
        email: "melat@aimga.ca",
        phone: "403-452-6534",
      },
      {
        name: "Roman Sorokin",
        email: "roman@aimga.ca",
        phone: "587-358-2976",
      },
    ],
  },
  {
    id: 3,
    name: "Career Advisors Team",
    hasChildrens: true,
    staffData: [
      {
        name: "Rossalia Videlova",
        email: "rossalia@aimga.ca",
        phone: "403-452-6521",
      },
      {
        name: "Teresa Lau",
        email: "teresa@aimga.ca",
        phone: "825-540-5404",
      },
      {
        name: "Shola Adelakun",
        email: "shola@aimga.ca",
        phone: "-",
      },
    ],
  },
  {
    id: 4,
    name: "Research and Evaluation Team",
    hasChildrens: true,
    staffData: [
      {
        name: "Punom Kumar Bhattacharyya",
        email: "punom@aimga.ca",
        phone: "587-391-9453",
      },
      {
        name: "Onyinyechi Onuoha",
        email: "-",
        phone: "-",
      },
    ],
  },
  {
    id: 5,
    name: "Coordination Team",
    hasChildrens: true,
    staffData: [
      {
        name: "Edna Ramirez Cerino",
        email: "edna@aimga.ca",
        phone: "-",
      },
      {
        name: "Joyce Lam",
        email: "joyce@aimga.ca",
        phone: "-",
      },
      {
        name: "Drew Bowman",
        email: "drew@aimga.ca",
        phone: "-",
      },
      {
        name: "Sarbast Rashid",
        email: "sarbast@aimga.ca",
        phone: "-",
      },
    ],
  },
  {
    id: 6,
    name: "Vaccine Navigator Team",
    hasChildrens: true,
    staffData: [
      {
        name: "Samah Elzahaby",
        email: "samah@aimga.ca",
        phone: "-",
      },
      {
        name: "Mohammad Yasir Essar",
        email: "yasir@aimga.ca",
        phone: "-",
      },
      {
        name: "Naxhielli Donaji Mendez Muniz",
        email: "naxhielli@aimga.ca",
        phone: "-",
      },
      {
        name: "Johandra Argote Parolis",
        email: "johandra@aimga.ca",
        phone: "-",
      },
      {
        name: "Abdul Hameed Khateer",
        email: "abdul@aimga.ca",
        phone: "-",
      },
      {
        name: "Atobrhan Godlu",
        email: "atobrhan@aimga.ca",
        phone: "-",
      },
      {
        name: "Atobrhan Godlu",
        email: "atobrhan@aimga.ca",
        phone: "-",
      },
    ],
  },
  {
    id: 7,
    name: "Consultants",
    hasChildrens: true,
    staffData: [
      {
        name: "Clementine Crooks",
        email: "clementine@aimga.ca",
        phone: "-",
      },
      {
        name: "Anne Babineau",
        email: "anne@aimga.ca",
        phone: "-",
      },
      {
        name: "Rachel Springle",
        email: "rachel@aimga.ca",
        phone: "-",
      },
      {
        name: "Kate Maguire",
        email: "kate@aimga.ca",
        phone: "-",
      },
      {
        name: "Ashish Rathi",
        email: "ashish@aimga.ca",
        phone: "-",
      },
    ],
  },
];

export const aimgaLoginAndSupportLink = {
  TECH_SUPPORT: "https://forms.office.com/r/NF3xxWnJhg",
  STAFF_LOGIN: `${BASE_URL}/wp-admin/`
}

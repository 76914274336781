import { Routes, Route } from "react-router-dom";
import "./App.scss";
import {
  Home,
  PageNotFound,
} from "./screens";
import { routes } from "./utils/routes";

function App() {
  return (
    <main>
      <Routes>
        <Route path={routes.HOME} element={<Home />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </main>
  );
}

export default App;

import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./styles.module.scss";
import { ChevronDown } from "react-bootstrap-icons";
import { Anchor, Text } from "../styledComponent";

const CustomAccordian = ({
  id,
  title,
  description,
  activeAccordian,
  currentAccordian,
  childrenData,
  staffData,
}) => {
  return (
    <li
      className={
        currentAccordian
          ? `${styles.activeAccordian} ${styles.accordianBox}`
          : styles.accordianBox
      }
      onClick={() => {
        currentAccordian ? activeAccordian(false) : activeAccordian(id);
      }}
    >
      <motion.button
        initial={false}
        className={styles.accordianHeader}
        whileTap={{ scale: 0.97 }}
      >
        <span>{title}</span>
        <span>
          <ChevronDown size={15} color="var(--whiteColor)" />
        </span>
      </motion.button>
      <AnimatePresence initial={false}>
        {currentAccordian && (
          <motion.div
            className={styles.accordianBody}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {description && <Text>{description}</Text>}
            {childrenData && (
              <ul className={styles.nestedList}>
                {childrenData.map((val, i) => (
                  <li key={i}>
                    <Anchor href={val.link} target="_blank">
                      {val.name}
                      <ChevronDown size={15} color="var(--whiteColor)" />
                    </Anchor>
                  </li>
                ))}
              </ul>
            )}
            {staffData && (
              <ul className={styles.staffList}>
                {staffData.map((val, i) => (
                  <li key={i}>
                    <span>{val.name}</span>
                    <span>{val.email}</span>
                    <span>{val.phone}</span>
                  </li>
                ))}
              </ul>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  );
};

export default CustomAccordian;

export const routes = {
    HOME: "/",
    CONTACT: "/contact",
    ABOUT: "/about",
    SUPPLIER: "/suppliers",
    PRODCUTS: "/products",
    PRE_GALVANIZED_TUBES: "/pre-galvanized-tubes",
    GALVANIZED_IRON_PIPES: "/galvanized-iron-pipes-gi",
    SCAFFOLDING_PIPES: "/scaffolding-pipe-safescaf",
    MS_PIPES: "/ms-pipe-black-pipe",
    HOLLOW_PIPE: "/hollow-structural-sections-hss",
    OCTG_WELDED: "/octg-welded-and-seamless-tubes",
    GALVANIZED_WIRE: "/hot-dip-galvanized-wire",
    GALVANIZED_STEEL: "/hot-dip-galvanized-steel",
    COLOR_COATED_STEEL: "/color-coated-steel",
    COLOR_ROLLED_STEEL: "/color-rolled-steel",
}
import React, { useState } from "react";
import styles from "./style.module.scss";
// import { routes } from "../../utils/routes";
// import { Container, Row, Col } from "react-bootstrap";
import {
  HowTo,
  ImportantLinks,
  Main,
  StaffContacts,
  StaffPages,
} from "../../components/sections";

const Home = () => {
  const [activeSection, setActiveSection] = useState(1);
  return (
    <div className={styles.homePage}>
      <Main
        onClick={() => setActiveSection(1)}
        isActive={activeSection === 1}
      />
      <ImportantLinks
        onClick={() => setActiveSection(2)}
        isActive={activeSection === 2}
      />
      <StaffPages
        onClick={() => setActiveSection(3)}
        isActive={activeSection === 3}
      />
      <StaffContacts
        onClick={() => setActiveSection(4)}
        isActive={activeSection === 4}
      />
      <HowTo
        onClick={() => setActiveSection(5)}
        isActive={activeSection === 5}
      />
    </div>
  );
};

export default Home;
